
import { getFragment } from "@/application/util/url";
import { mapActions, mapMutations } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "AuthorisationCallback",
  async created() {
    const redirectUri = this.$route.query.redirect_uri as string;

    const token = getFragment("token");

    if (!token) {
      throw new Error("AuthorisationCallback::created token hash not found");
    }

    this.setAuthorisationToken(token);
    await this.fetchProfile();
    await this.$router.push({ name: redirectUri });
  },
  methods: {
    ...mapMutations({
      setAuthorisationToken: "authorisation/setAuthorisationToken",
    }),
    ...mapActions({
      fetchProfile: "authorisation/fetchProfile",
    }),
  },
});
